import { ROUTE_TYPE_NORMAL } from '@rikstv/play-common/src/router/routes.type';

import { NotFoundPage } from '../components/notFound/NotFoundPage';
import { LayoutDark } from '../containers/layout/LayoutDark';

import { devRoute } from './router.dev';
import { routesAccount } from './routes.account';
import { routesAuth } from './routes.auth';
import { routesCompany } from './routes.company';
import { routesLandingPage } from './routes.landingPage';
import { routesPlayApp } from './routes.playApp';
import { routesSales } from './routes.sales';

// "404", catches all unknown paths.
const catchAllRoute = {
  type: ROUTE_TYPE_NORMAL,
  path: '*',
  component: NotFoundPage,
  layout: LayoutDark,
  waitForStartup: true,
};

const routes = [
  ...routesLandingPage,
  ...routesCompany,
  ...routesSales,
  ...routesAccount,
  ...routesAuth,
  ...routesPlayApp,
  ...devRoute,
  catchAllRoute,
];
export { routes };
