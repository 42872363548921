import { authService } from '../auth/AuthService';

import { OPEN_FEATURE_FLAGS, OpenFeatureFlags } from './featureFlags';

const defaultEnableForEmployees: OpenFeatureFlags[] = [];
const defaultEnable: OpenFeatureFlags[] = [];

const onValue = '1';
const offValue = '0';

const getKey = (key: string) => `dev-feature-${key}`;

export const toggleDevFeature = (feature: OpenFeatureFlags, enabled: boolean) => {
  if (!enabled) {
    localStorage.removeItem(getKey(feature));
  } else {
    localStorage.setItem(getKey(feature), onValue);
  }
};

export const isDevFeatureEnabled = (feature: OpenFeatureFlags) => {
  return (
    (globalThis as any)._e2eDevFlags?.[feature] === onValue ||
    location.search.includes(feature) ||
    localStorage.getItem(getKey(feature)) === onValue ||
    (defaultEnableForEmployees.includes(feature) && authService.isInternalUser()) ||
    defaultEnable.includes(feature)
  );
};

const setFeatureFlagByUrl = () => {
  // Set feature flags via URL by adding ?dev=[some,feature]
  const devFlagsFromURL = new URLSearchParams(location.search).get('dev');

  if (devFlagsFromURL) {
    devFlagsFromURL
      .split('[')[1]
      .split(']')[0]
      .split(',')
      .forEach(featureStr => {
        const feature = featureStr.replace('!', '').trim() as OpenFeatureFlags;
        if (OPEN_FEATURE_FLAGS[feature]) {
          localStorage.setItem(getKey(feature), featureStr.startsWith('!') ? offValue : onValue);
        }
      });
  }
};

// Clear old feature flags, simply everything that was not set today, when loading this module
const init = () => {
  const now = new Date();
  const todayString = new Date(now.getFullYear(), now.getMonth(), now.getDate()).toISOString();
  if (localStorage.getItem('dev-feature-date') !== todayString) {
    Object.keys(OPEN_FEATURE_FLAGS).forEach(key => localStorage.removeItem(getKey(key)));
  }
  localStorage.setItem('dev-feature-date', todayString);

  setFeatureFlagByUrl();
};
try {
  init();
} catch {
  /* on nom nom */
}
