import React from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { Loader } from '@rikstv/shared-components';

import { hasSucceededLoadingUserInfo } from '../forces/loadUserInfo/selectors';
import { useIsAuthenticated } from '../hooks/useIsAuthenticated';
import { authService } from '../utils/auth/AuthService';

import style from './WaitForStartupWrapper.module.css';

interface Props {
  waitForStartup: boolean;
  loginRequired: boolean; // show page only if user is logged in
  children: React.ReactElement;
  redirectPath: string;
}

export const WaitForStartupWrapper: React.FC<Props> = ({ children, loginRequired, redirectPath, ...access }) => {
  const isLoggedIn = useIsAuthenticated();

  const isUserInfoLoaded = useSelector(hasSucceededLoadingUserInfo);
  const navigate = useNavigate();

  // no waiting for these ones
  if (!access.waitForStartup) {
    return children;
  }

  if (isLoggedIn && !isUserInfoLoaded) {
    return (
      <div className={style.wrapper}>
        <Loader defaultValue={500} size="large" label="Henter innhold" />
      </div>
    );
  }

  // Page is protected and requires authenticated user
  if (loginRequired && !isLoggedIn) {
    // Go to frontpage if reauthentication fails
    authService.login().catch(() => navigate(redirectPath));
    return null;
  }

  return children;
};
