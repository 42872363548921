import { create } from 'zustand';

import config from '../../config';
import { request } from '../../forces/utils/request';
import { authService } from '../../utils/auth/AuthService';

interface usePinStore {
  isPinSet?: boolean;
  isPurchasePinActive?: boolean;
  parentalSetting?: ParentalSetting;
  validatedTitles: string[];
  isValidatingPin: boolean;
  pinMessage: PinMessage;
  validatePin: (pin: string, assetId?: string) => Promise<boolean>;
  setNewPin: (newPin: string, firstTimeSetup?: boolean, onValidation?: () => void) => void;
  assetIsAgeRestricted: (ageRestriction?: number) => boolean;
  setParentalSetting: (parentalSettings: ParentalSetting) => void;
  getParentalSettings: () => void;
}

type ParentalSetting =
  | { isParentalControlActive: false; ageRestriction: undefined }
  | { isParentalControlActive: true; ageRestriction: number };

export type PinMessage = { type: 'error' | 'success'; message: string } | undefined;

export const usePinStore = create<usePinStore>()((set, get) => ({
  isPinSet: undefined,
  isPurchasePinActive: undefined,
  parentalSetting: undefined,
  validatedTitles: [],
  isValidatingPin: false,
  pinMessage: undefined,
  changedPinMessage: undefined,
  // Actions
  validatePin: async (pin, assetId) => {
    // return false if pin input contains illegal characters
    if (/^\d*$/.test(pin) === false) {
      set(() => ({
        pinMessage: { type: 'error', message: 'Feil pin, bruk kun tall' },
      }));
      return false;
    }

    const accessToken = authService.getToken().value;
    const body = JSON.stringify({
      pin,
    });

    try {
      set({ isValidatingPin: true, pinMessage: undefined });

      const response = await request<{ isValid: boolean }>(
        {
          url: config.apiBaseURL,
          path: '/pin/validate',
          method: 'POST',
          headers: {
            'accept-modal-version': '2.0',
          },
          body,
        },
        accessToken
      );

      if (response.isValid) {
        if (assetId) {
          set(state => ({ validatedTitles: [...state.validatedTitles, assetId] }));
        }
        return response.isValid;
      }
      set(() => ({ pinMessage: { type: 'error', message: 'Feil pin' } }));
      return response.isValid;
    } catch {
      set({ pinMessage: { type: 'error', message: 'Noe gikk galt. Førsøk igjen' } });
      return false;
    } finally {
      set({ isValidatingPin: false });
    }
  },
  setNewPin: async (newPin: string, firstTimeSetup?: boolean, onValidation?: () => void) => {
    const accessToken = authService.getToken().value;
    const body = JSON.stringify({ purchasePin: newPin });

    if (/^\d*$/.test(newPin) === false) {
      set(() => ({ pinMessage: { type: 'error', message: 'PIN-kode må bestå av 4 siffer' } }));
      return;
    }
    try {
      set({ isValidatingPin: true });
      await request<string>(
        {
          url: config.apiBaseURL,
          path: '/pin/settings/purchasepin',
          method: 'PUT',
          headers: {
            'accept-modal-version': '2.0',
          },
          body,
        },
        accessToken
      );
      onValidation?.();
      set({
        isPinSet: true,
        isValidatingPin: false,
        pinMessage: { type: 'success', message: firstTimeSetup ? 'Ny pin är opprettet' : 'Pin er oppdatert' },
      });
    } catch (error) {
      set({
        isValidatingPin: false,
        pinMessage: { type: 'error', message: 'Oi, no gikk det gale, prøv igjen seinare du' },
      });
    }
  },
  assetIsAgeRestricted: assetAgeRestriction => {
    const parentalSettings = get().parentalSetting;
    if (!parentalSettings?.isParentalControlActive || assetAgeRestriction === undefined) {
      return false;
    }

    return assetAgeRestriction > parentalSettings.ageRestriction;
  },
  setParentalSetting: setParentalSetting,
  getParentalSettings: () => getParentalSetting(),
}));

type PinResponse = {
  purchasePinActive: boolean;
  isPinSet: boolean;
};

async function getPinSettings() {
  await authService.whenReady();
  const accessToken = authService.getToken().value;
  const headers = { 'accept-model-version': '2.0' };
  const url = `${config.apiBaseURL}/pin/pinsettings`;

  if (!authService.isAuthenticated()) {
    return;
  }

  const pinSettings = await request<PinResponse>({ url, headers }, accessToken);

  usePinStore.setState({ isPinSet: pinSettings.isPinSet, isPurchasePinActive: pinSettings.purchasePinActive });

  return;
}
// immediately invoke function to get settings
getPinSettings();
getParentalSetting();

function setParentalSetting(parentalSettings: ParentalSetting) {
  const userId = authService.getUserData()?.userId;

  if (!userId) {
    return;
  }
  // Simple obfuscation by converting to base64
  const obfuscatedSettings = btoa(JSON.stringify(parentalSettings));

  localStorage.setItem(`ps24${userId}`, obfuscatedSettings);
  usePinStore.setState({ parentalSetting: parentalSettings });
}

async function getParentalSetting() {
  await authService.whenReady();
  const userId = authService.getUserData()?.userId;
  // Retrieve parental controls from localStorage
  const obfuscatedSettings = localStorage.getItem(`ps24${userId}`);
  //If the user is missing the parental controls object, create a new one with parental controlls deactivated
  if (!obfuscatedSettings) {
    setParentalSetting({ isParentalControlActive: false, ageRestriction: undefined });
    usePinStore.setState({
      parentalSetting: {
        isParentalControlActive: false,
        ageRestriction: undefined,
      },
    });
    return;
  }

  try {
    const decodedSettings = JSON.parse(atob(obfuscatedSettings));

    usePinStore.setState({
      parentalSetting: {
        isParentalControlActive: decodedSettings.isParentalControlActive,
        ageRestriction: decodedSettings.ageRestriction,
      },
    });
  } catch (error) {
    console.error('Failed to decode parental settings:', error);
    return undefined;
  }
}
