import { create } from 'zustand';
import { createJSONStorage, persist } from 'zustand/middleware';

import { TrailerObject } from '@rikstv/play-common/src/pages/StackedCoverPage/contentStack.types';
import { authService } from '@rikstv/play-common/src/utils/auth/AuthService';

interface TrailerPlayerStore {
  backgroundTrailerSettings: { [key: string]: boolean };
  enableBackgroundTrailers: () => boolean;
  playBackgroundTrailer: boolean;
  trailerSources: TrailerSource[] | undefined;
  setEnableBackgroundTrailer: (enableTrailer: boolean) => void;
  setPlayBackgroundTrailer: (play: boolean) => void;
  setTrailerSources: (trailerSources: TrailerObject[] | undefined) => void;
}

export type TrailerSource = { src: string; type: string };

export const useTrailerPlayerStore = create<TrailerPlayerStore>()(
  persist(
    (set, get) => ({
      backgroundTrailerSettings: {},
      enableBackgroundTrailers: () => {
        const userId = authService.getUserData()?.userId;

        //Background trailers should always be enabled for users that are not logged in
        if (!userId) {
          return true;
        }

        const backgroundTrailerSettings = get().backgroundTrailerSettings;

        // if the key is not found in the settings, default to true
        return backgroundTrailerSettings[userId] ?? true;
      },
      playBackgroundTrailer: true,
      trailerSources: undefined,
      //Actions
      setEnableBackgroundTrailer: (enableTrailer: boolean) => {
        const userId = authService.getUserData()?.userId;

        if (!userId) {
          return;
        }

        set(state => ({
          backgroundTrailerSettings: {
            ...state.backgroundTrailerSettings,
            [userId]: enableTrailer,
          },
        }));
      },
      setPlayBackgroundTrailer: (play: boolean) => set({ playBackgroundTrailer: play }),
      setTrailerSources: (trailerSources: TrailerObject[] | undefined) =>
        set({
          trailerSources: trailerSources
            ? trailerSources.map(trailer => ({
                src: trailer.playUri,
                type: trailer.format === 'Dash' ? 'application/dash+xml' : 'application/x-mpegurl',
              }))
            : undefined,
        }),
    }),
    {
      name: 'trailer-settings-store',
      storage: createJSONStorage(() => localStorage),
      partialize: state => ({ backgroundTrailerSettings: state.backgroundTrailerSettings }),
    }
  )
);
