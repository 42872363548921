import { FC } from 'react';

import { LinkButton } from '@rikstv/play-common/src/components/linkButton/LinkButton';
import { PageFrame } from '@rikstv/play-common/src/components/pageFrame/PageFrame';
import { errorTracker } from '@rikstv/play-common/src/utils/errorTracker/tracking';
import { NotFound } from '@rikstv/shared-strim-components';

import { shouldAttemptRedirectToLowerCasePath } from '../../../hacks/caseSensitiveCloudfrontHack';
import routerPath from '../../router/router.path';

export const NotFoundPage: FC = () => {
  // can end up here for valid but case-different urls that should hit WebApp
  // the issue being that CloudFront Routing is case sensitive...
  if (shouldAttemptRedirectToLowerCasePath()) {
    window.location.pathname = window.location.pathname.toLowerCase();
    return null;
  }

  // report 404 to Google Analytics
  window.dataLayer && window.dataLayer.push({ event: '404page' });
  errorTracker.capture404NotFound();

  return (
    <PageFrame centerPadding title="Beklager! Vi fant ikke siden du lette etter" meta="noindex" renderTitle={false}>
      <NotFound>
        <LinkButton buttonStyle="primary" to={routerPath.frontpage()}>
          Tilbake til forsiden
        </LinkButton>
        <LinkButton buttonStyle="secondary" transparent to={routerPath.help()} withArrow>
          Til hjelpesider
        </LinkButton>
      </NotFound>
    </PageFrame>
  );
};
