import React from 'react';

import { AppRoutes, ROUTE_TYPE_NORMAL } from '@rikstv/play-common/src/router/routes.type';

import { LayoutDarker } from '../containers/layout/LayoutDarker';

import { routerPaths } from './router.path';

const SubscriptionSelector = React.lazy(() =>
  import('../../domain/packages/containers/subscriptions/SubscriptionSelector').then(module => ({
    default: module.SubscriptionSelector,
  }))
);

const SignupPageRouter = React.lazy(() =>
  import('../../domain/user/containers/SignupPageRouter').then(module => ({
    default: module.SignupPageRedirectRouter,
  }))
);
const SalesFlowPageSelectMaxUpgrades = React.lazy(() =>
  import('../../pages/SalesFlowPageSelectMaxUpgrades').then(module => ({
    default: module.SalesFlowPageSelectMaxUpgrades,
  }))
);

export const routesSales: AppRoutes = [
  {
    type: ROUTE_TYPE_NORMAL,
    path: routerPaths.signUpPackageSelect,
    component: SubscriptionSelector,
    layout: LayoutDarker,
    props: { hasFooter: false },
    waitForStartup: false,
  },
  {
    type: ROUTE_TYPE_NORMAL,
    path: [routerPaths.signUpRegisterAccount, `${routerPaths.signUpPackageSelect}/:id`],
    component: SignupPageRouter,
    layout: LayoutDarker,
    props: { hasFooter: false },
    waitForStartup: false,
  },
  {
    type: ROUTE_TYPE_NORMAL,
    path: [routerPaths.signUpSelectMaxUpgrades],
    component: SalesFlowPageSelectMaxUpgrades,
    layout: LayoutDarker,
    props: { hasFooter: false },
    waitForStartup: false,
  },
];
