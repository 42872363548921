import { FC } from 'react';
import { ComponentPropsWithoutRef } from 'react';

import { BaseLinkButton } from '@rikstv/shared-components';
import { BaseLinkButtonProps } from '@rikstv/shared-components/dist/components/button/BaseLinkButton';

import { Link } from '../../router/Link';

type RawLinkProps = Omit<ComponentPropsWithoutRef<typeof Link>, 'withDefaultStyle'>;

const RawLink: FC<RawLinkProps> = ({ children, ...rest }) => (
  <Link withDefaultStyle={false} {...rest}>
    {children}
  </Link>
);

type LinkBtnProps = BaseLinkButtonProps<RawLinkProps>;

export const LinkButton = (props: LinkBtnProps) => <BaseLinkButton element={RawLink} {...props} />;
