// Feature flags defined locally
export const LOCAL_FEATURE_FLAGS = Object.freeze({
  deepLinkGeneratorButton: 'Enable deep link generator button',
  disableScpRedirect: 'Disable redirect to SCP',
  superHero: 'Enable super hero',
  darkerLayout: 'Enable darker layout',
});

// Feature flags defined in Launch Darkly that are available to override
// in dev/null page
export const OPEN_REMOTE_FEATURE_FLAGS = Object.freeze({
  'enable-braintree-payment': 'web-strim-braintree-payment',
  'modal-dev-page': 'web-riks-tv-strim-modal-dev-page',
  'enable-vipps-signup': 'web-strim-vipps-signup',
  'enable-vipps-payment': 'web-strim-vipps-payment',
  'account-alerts': 'strim-web-account-alerts',
  // EXPERIMENTS, flags with percentage rollout and control options (docs: file://./../../../../../../docs/feature-flags.md)
  // Local name must start with "exp"
  'exp-modals': 'web-strim-modals-experiment',
});

// Feature flags defined in Launch Darkly that is not available to override
const SECRET_REMOTE_FEATURE_FLAGS = Object.freeze({});

// All flags defined in Launch Darkly
export const REMOTE_FEATURE_FLAGS = Object.freeze({
  ...OPEN_REMOTE_FEATURE_FLAGS,
  ...SECRET_REMOTE_FEATURE_FLAGS,
});

// All overridable flags
export const OPEN_FEATURE_FLAGS = Object.freeze({
  ...LOCAL_FEATURE_FLAGS,
  ...OPEN_REMOTE_FEATURE_FLAGS,
});

export const ALL_FEATURE_FLAGS = Object.freeze({
  ...REMOTE_FEATURE_FLAGS,
  ...LOCAL_FEATURE_FLAGS,
});

// types
export type LocalFeatureFlags = keyof typeof LOCAL_FEATURE_FLAGS;
export type RemoteFeatureFlags = keyof typeof REMOTE_FEATURE_FLAGS;
export type OpenFeatureFlags = keyof typeof OPEN_FEATURE_FLAGS;
export type AllFeatureFlags = keyof typeof ALL_FEATURE_FLAGS;

export type RemoteFeatureFlagKeys = (typeof REMOTE_FEATURE_FLAGS)[RemoteFeatureFlags];
