import React from 'react';

import config from '@rikstv/play-common/src/config';
import { AppRoutes, ROUTE_TYPE_EXTERNAL_REDIRECT, ROUTE_TYPE_NORMAL } from '@rikstv/play-common/src/router/routes.type';

import { LayoutDark } from '../containers/layout/LayoutDark';
import OAuthCallback from '../containers/oauthCallback/OAuthCallback';

import { routerPaths } from './router.path';

const Logout = React.lazy(() => import('../containers/logout/Logout'));

const routes: AppRoutes = [
  {
    type: ROUTE_TYPE_NORMAL,
    path: routerPaths.oAuthCallback,
    component: OAuthCallback,
    layout: LayoutDark,
    waitForStartup: false,
  },
  {
    type: ROUTE_TYPE_EXTERNAL_REDIRECT,
    path: [routerPaths.appletv, routerPaths.deviceLogin],
    redirectPath: `${config.auth.stsUrl}/device`,
  },
  {
    type: ROUTE_TYPE_NORMAL,
    path: routerPaths.logout,
    component: Logout,
    layout: LayoutDark,
    waitForStartup: false,
  },
  {
    type: ROUTE_TYPE_NORMAL,
    path: routerPaths.logoutCallback,
    component: Logout,
    layout: LayoutDark,
    waitForStartup: false,
  },
];

export { routes as routesAuth };
