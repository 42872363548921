import type { UserManagerSettings } from 'oidc-client-ts';
import { Log, User, UserManager } from 'oidc-client-ts';

import history from '@rikstv/play-common/src/router/history';

import { AuthConfig } from '../../../config';
import { AccessToken } from '../../../forces/auth/auth.types';
import { getLogger } from '../../logger/logger';
import { Deferred } from '../../promises/Deferred';
import type { AuthServiceClass } from '../AuthService';
import { JwtToken } from '../jwtToken';
import { getUserStorage } from '../userStorage';

const log = getLogger('[auth]');

// oidc-client log level
Log.setLogger(console);
Log.setLevel(Log.NONE);

type InitStates = 'signedIn' | 'signedOut' | 'signInFailed' | 'noAction';

type AuthClassPublicProps = Pick<AuthServiceClass, keyof AuthServiceClass>;

const deferredObject = new Deferred<InitStates>();
class MockAuthService implements AuthClassPublicProps {
  private logoutCallbackUrl: Readonly<URL>;
  private userManager: Readonly<UserManager>;
  private token: JwtToken = new JwtToken(null);
  whenReady = deferredObject.then;

  constructor(authConfigOverrides?: Partial<typeof AuthConfig>) {
    const authConfig = { ...AuthConfig, ...authConfigOverrides };
    this.logoutCallbackUrl = new URL(authConfig.oidcPostLogoutUrl);

    const usrMgrSettings: UserManagerSettings = {
      authority: authConfig.stsUrl,
      scope: authConfig.scope,
      client_id: authConfig.clientId,
      redirect_uri: '',
      response_type: 'code',
      loadUserInfo: false,
      includeIdTokenInSilentRenew: true,
      validateSubOnSilentRenew: true,
      revokeTokensOnSignout: true,
      accessTokenExpiringNotificationTimeInSeconds: 3,
      userStore: getUserStorage(),
      monitorSession: false,
      automaticSilentRenew: false,
      silent_redirect_uri: authConfig.oidcSilentRenewUrl,
      silentRequestTimeoutInSeconds: 2,
    };
    this.userManager = new UserManager(usrMgrSettings);
  }

  async init(): Promise<InitStates> {
    log.info('init: Initializing MOCK AuthService');

    try {
      const mockUser = sessionStorage.getItem('e2e-test-user') || '';
      await this.userManager.storeUser(User.fromStorageString(mockUser));
      const user = await this.userManager.getUser();
      this.token = new JwtToken(user?.access_token ?? null);
    } catch (err) {
      return 'noAction';
    }
    deferredObject.resolve('signedIn');
    return 'signedIn';
  }

  isAuthenticated(): boolean {
    return Boolean(this.token.accessToken || sessionStorage.getItem('e2e-test-user.token'));
  }

  isInternalUser(): boolean {
    return false;
  }

  async login() {
    return;
  }

  async redirectToAuthServerAfterPasswordReset() {
    return;
  }
  async loginSilentWithOneTimeCode() {
    return;
  }

  async logout() {
    await this.userManager.removeUser();
    localStorage.clear();
    sessionStorage.clear();
    location.href = this.logoutCallbackUrl.toString();
    return;
  }

  async renewTokens({ redirectUrl = '' }: { redirectUrl?: string } = {}) {
    if (redirectUrl && redirectUrl !== window.location.href) {
      history.push(redirectUrl);
    }
    return Promise.resolve();
  }

  onTokenChanged() {
    return () => void 0;
  }

  getToken(): AccessToken {
    return {
      value: this.token.accessToken || sessionStorage.getItem('e2e-test-user.token') || '',
      // Explicity set a expiry long into the future
      expiry: 1897293900000, // Thursday, February 14, 2030 10:05:00 AM
    };
  }

  getUserData() {
    const decoded = this.token.valueOf();
    const { sub, email } = decoded || {};
    const cid = decoded?.['urn:rikstv:1:cid'];
    const entitlements = ([] as string[]).concat(decoded?.['urn:rikstv:2:channel'] ?? []);
    return sub && email ? { userId: sub, email, cid, entitlements } : null;
  }

  async shouldRenewTokensOnRouteChange(): Promise<boolean> {
    return false;
  }
}

const isMockUser = sessionStorage.getItem('e2e-test-user') != null;

export { isMockUser, MockAuthService };
