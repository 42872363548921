import { ChangeEvent, FC, useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import classNames from 'classnames';
import type { SwimlaneType } from 'ContentLayout';
import { getLogger } from 'loglevel';
import { useSWRConfig } from 'swr';

import { toggleFavoriteChannelAction } from '@rikstv/play-common/src/forces/analytics/commonAnalyticsActions';
import { request } from '@rikstv/play-common/src/forces/utils/request';
import { useAuthToken } from '@rikstv/play-common/src/hooks/useAuthToken';
import { useIsAuthenticated } from '@rikstv/play-common/src/hooks/useIsAuthenticated';
import { ToggleInlineButton } from '@rikstv/shared-components';

import { StarIcon } from './starIcon';

import style from './favoriteChannelButton.module.css';

interface Props {
  className?: string;
  url: string;
  channelId: number;
  isDefaultFavorite: boolean;
}

const logger = getLogger('[favorite]');

const onTvNow: SwimlaneType = 'OnTvNow';

const useReloadOnTvNowSwimlane = () => {
  const { cache, mutate } = useSWRConfig();
  return () => {
    if (!(cache instanceof Map)) {
      return Promise.resolve();
    }

    const keys = Array.from(cache.keys()).filter((k: string) => k.includes(`"${onTvNow}"`));
    const mutations = keys.map(key => mutate(key));
    return Promise.all(mutations);
  };
};

const useToggleFavorite = (isFavorite: boolean, url: string) => {
  const token = useAuthToken();
  const reload = useReloadOnTvNowSwimlane();
  const toggleFavorite = useCallback(() => {
    request({ url, method: isFavorite ? 'DELETE' : 'POST' }, token)
      .then(() => setTimeout(() => reload(), 400))
      .catch(err => logger.error('Failed to toggle channel as favorite.', err));
  }, [token, isFavorite, url, reload]);

  return { toggleFavorite };
};

export const FavoriteChannelButton: FC<Props> = ({ className, url, isDefaultFavorite, channelId }) => {
  const dispatch = useDispatch();
  const [isFavorite, setIsFavorite] = useState(isDefaultFavorite);
  const isLoggedIn = useIsAuthenticated();
  const { toggleFavorite } = useToggleFavorite(isFavorite, url);

  const handleToggleFavoriteChange = (e: ChangeEvent<HTMLInputElement>) => {
    setIsFavorite(e.target.checked);
    toggleFavorite();
    dispatch(toggleFavoriteChannelAction({ channelId, isFavorite: e.target.checked }));
  };

  if (!isLoggedIn) {
    return null;
  }

  const label = isFavorite ? 'Fjern som favorittkanal' : 'Legg til som favorittkanal';
  return (
    <div className={classNames(`${style.wrapper} ${className}`, { [style.buttonSolid]: isFavorite })}>
      <ToggleInlineButton
        onChange={handleToggleFavoriteChange}
        onClick={e => {
          // Stop click event here to prevent surrounding link to be clicked
          e.preventDefault();
          e.stopPropagation();
        }}
        className={`${style.button} with-keyboard-focus-within`}
        labelClassName={style.label}
        inputClassName={style.input}
        defaultChecked={isFavorite}
        aria-live="polite"
        aria-label={label}
        title={label}
        data-context-menu>
        <StarIcon solid={isFavorite} style={{ width: '100%', height: '100%' }} />
      </ToggleInlineButton>
    </div>
  );
};
