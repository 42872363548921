import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface AuthorizationState {
  postAuthRedirectUrl: string | null;
}

const initialState: AuthorizationState = {
  postAuthRedirectUrl: null,
};

export const authSlice = createSlice({
  name: 'authorization',
  initialState,
  reducers: {
    loginSuccess: () => {},
    tokenRenewed: () => {},
    setPostAuthRedirectUrl: (state, { payload }: PayloadAction<string | null>) => {
      state.postAuthRedirectUrl = payload;
    },
    clearReAuthenticationRedirectUrl: state => {
      state.postAuthRedirectUrl = null;
    },
  },
});

export const { loginSuccess, tokenRenewed, clearReAuthenticationRedirectUrl, setPostAuthRedirectUrl } =
  authSlice.actions;

export default authSlice.reducer;
