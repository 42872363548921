import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import classNames from 'classnames';

import { PlayRoundIcon } from '@rikstv/play-player/src/icons';
import { H4, Meta, Tag } from '@rikstv/shared-components';

import { ProgramState, SwimlaneItem } from '../../../forces/assetDetails/assetDetails.types';
import { getImagePackSet } from '../../../utils/imagePack/imagePack.utils';
import { AssetImage } from '../../assetImage/AssetImage';
import { AssetImageContainer } from '../../assetImage/AssetImageContainer';
import { DurationBar } from '../../durationBar/DurationBar';
import { Breakpoints } from '../../grid/breakpoints';
import { ExpiredOverlay } from '../../imageOverlays/ExpiredOverlay';
import { ImageOverlayWrapper } from '../../imageOverlays/ImageOverlayWrapper';
import { Swimlane } from '../../pages/types';
import { ProviderImageWrapper } from '../../providerImage';
import { ContextMenu } from '../contextMenu/ContextMenu';
import { SwimlaneItemProps } from '../Swimlane.types';
import { getLinkTo } from '../swimlane.utils';

import { ItemWrapper } from './ItemWrapper';
import { AgeLimitPill, RightBottomLabel } from './StandardItem';

import './ContinueWatchingItem.scss';
import styles from './ContinueWatchingItem.module.css';

const ProgressInfo: FC<{ asset: SwimlaneItem }> = ({ asset }) => {
  const progressPercent = asset.userData?.progressRatio ? asset.userData?.progressRatio * 100 : null;
  const progressLabel = asset.userData?.progressLabel;

  if (!progressPercent) {
    return null;
  }

  return (
    <div className={styles.progress}>
      {progressLabel && <Tag className={styles.progressLabel}>{progressLabel}</Tag>}
      <DurationBar percent={progressPercent} />
    </div>
  );
};

interface Props extends SwimlaneItemProps {
  swimlane: Swimlane;
  setOptimisticFilter?: (filterToAppend: string) => void;
  preventNavigation?: boolean;
  onClick?: () => void;
}

const UnavailableStates: readonly ProgramState['type'][] = ['Expired', 'NoAccess', 'Planned'];

export const ContinueWatchingItem: FC<Props> = ({
  asset,
  swimlane,
  setOptimisticFilter,
  preventNavigation,
  onClick,
}) => {
  const navigate = useNavigate();
  const { programState } = asset;
  return (
    <ItemWrapper
      className={classNames('continue-watching-item', styles.continueWatchingItem)}
      linkTo={getLinkTo(asset, true)}
      ariaLabel={asset.title}
      preventDefault={preventNavigation}
      onClick={event => {
        onClick?.();
        // no autoplay when clicking fig-captions
        const { target } = event;
        if (target instanceof HTMLElement && target.closest(`.${styles.titleWrapper}`)) {
          event.preventDefault();
          navigate(getLinkTo(asset, false));
        }
      }}>
      <AssetImageContainer>
        <AssetImage
          blackWhite={UnavailableStates.includes(programState.type)}
          imageSet={getImagePackSet({
            url: asset.image.url,
            variations: [{ width: 460, height: 258, breakpoint: Breakpoints.DEFAULT }],
          })}
          alt=""
        />
        {programState.type === 'Expired' && <ExpiredOverlay label={programState.label} />}
        <ImageOverlayWrapper backdropStyle="gradient" style={{ pointerEvents: 'none' }}>
          <ProviderImageWrapper logos={asset.logos} />
          {!asset.userData?.progressRatio && <RightBottomLabel label={asset.rightBottomLabel} />}
          <ProgressInfo asset={asset} />
        </ImageOverlayWrapper>
        {programState.type !== 'Expired' && (
          <ImageOverlayWrapper displayChildrenOnHover centerContent className={styles.playWrapper}>
            <PlayRoundIcon className={styles.playIcon} />
          </ImageOverlayWrapper>
        )}
        <AgeLimitPill ageLimit={asset.ageLimit} />
      </AssetImageContainer>
      <div>
        <div className={styles.titleWrapper}>
          <H4 className={styles.title} title={asset.title}>
            {asset.title}
          </H4>
          <ContextMenu
            asset={asset}
            swimlane={swimlane}
            ariaLabel={'Flere alternativer'}
            setOptimisticFilter={setOptimisticFilter}
          />
        </div>
        <Meta className="continue-watching-item__subtitle">{asset.subtitle}</Meta>
      </div>
    </ItemWrapper>
  );
};
