import { PageMeta } from 'ContentLayout';
import useSWRImmutable from 'swr/immutable';

import config from '@rikstv/play-common/src/config';

import { request } from '../../forces/utils/request';
import { useAuthToken } from '../../hooks/useAuthToken';

import { mapResponseToShallowPages } from './mappers';
import { PageShallow } from './types';

const url = `${config.contentLayoutApiUrl}/pages`;

// TODO: add local-storage cache for pages
export const usePages = (): {
  pages: PageShallow[] | undefined;
  defaultPage: PageShallow | undefined;
  error: Error;
} => {
  const accessToken = useAuthToken();

  const { data, error } = useSWRImmutable(url ? [url, accessToken] : null, async ([url, accessToken]) => {
    const res = await request<PageMeta[]>({ url }, accessToken);
    return res ? mapResponseToShallowPages(res) : undefined;
  });

  return { pages: data, defaultPage: data?.[0] ?? undefined, error };
};
