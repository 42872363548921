import { FC, PropsWithChildren } from 'react';

import { Body, H1 } from '@rikstv/shared-components';

import { ReactComponent as LookingForSomethingIllustrationSvg } from './illustration.svg';

import styles from './NotFound.module.css';

export const NotFound: FC<PropsWithChildren> = ({ children }) => {
  return (
    <>
      <div className={styles.illustrationContainer}>
        <LookingForSomethingIllustrationSvg className={styles.illustration} />
      </div>
      <div className={styles.infoContainer}>
        <H1>Beklager! Vi fant ikke siden du lette etter</H1>
        <Body>
          Gå til forsiden for en frisk start, eller du kan besøke våre hjelpesider dersom du står fast på noe.
        </Body>
        <div className={styles.buttonsContainer}>{children}</div>
      </div>
    </>
  );
};
